<script>

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      timeout: null,
      sliderHeight: 0,
      sliderWidths: [],
      slidesCalculated: false,
      currentSlide: 0,
      loadedMedia: 0,
      showContent: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.debounceMediaResize);
    this.calculcateMediaDimensions();
    this.addIntersectionObserver();
  },
  unmounted() {
    window.removeEventListener('resize', this.debounceMediaResize);
  },
  watch: {
    loadedMedia() {
      if (this.loadedMedia === this.slideContent.length) {
        this.$refs.container.scrollLeft = 0;
        this.showContent = true;
      }
    },
  },
  computed: {
    intro() {
      return this.block.fields.Intro;
    },
    slideContent() {
      return this.block.fields.Slides.map((s, idx) => {
        const overlays = this.overlayPositions(s);
        return {
          ...s,
          link: this.slideLink(s),
          element: this.getSlideElement(s),
          hasMedia: this.hasMedia(s),
          isVideo: this.isVideo(s),
          isAutoplay: this.isAutoplay(s),
          mediaSource: this.getMediaSource(s),
          slideWidth: this.sliderWidths[idx],
          body: s.OverlayBody,
          overlayClasses: Object.keys(overlays).map((position) => {
            return {
              name: position,
              items: overlays[position].map((i) => {
                return this.getOverlayClassFor(i);
              }),
            };
          }),
        };
      });
    },
    hasSlideContent() {
      return this.slideContent.length > 0;
    },
    displaySingleContent() {
      return this.slideContent.length === 1;
    },
    sliderStyle() {
      const slidePadding = 16;
      return {
        height: `${this.sliderHeight + slidePadding}px`,
      };
    },
    listAndNavStyle() {
      return {
        visibility: this.showContent ? 'visible' : 'hidden',
        scrollBehavior: this.showContent ? 'smooth' : 'auto',
      };
    },
    canScrollRight() {
      return this.currentSlide < this.slideContent.length - 1;
    },
    canScrollLeft() {
      return this.currentSlide > 0;
    },
  },
  methods: {
    addIntersectionObserver() {
      const slides = document.querySelectorAll('.content-slider__slide');
      const options = {
        root: this.$refs.container,
        rootMargin: '0px -50% 0px -50%',
      };

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('content-slider__slide--focus');
            this.currentSlide = [...slides].indexOf(entry.target);
          } else {
            entry.target.classList.remove('content-slider__slide--focus');
          }
        });
      };

      const observer = new IntersectionObserver(callback, options);

      slides.forEach((element) => {
        observer.observe(element);
      });
    },
    debounceMediaResize() {
      const later = () => {
        clearTimeout(this.timeout);
        this.calculcateMediaDimensions();
      };

      clearTimeout(this.timeout);
      this.timeout = setTimeout(later, 100);
    },
    calculcateMediaDimensions() {
      if (!this.hasSlideContent) return;

      const twoThirdScreen = Math.floor((window.innerWidth / 3) * 2);
      const headerHeight = 45;
      const sliderPaddingsTotal = 46;
      const sliderMaxHeight =
        window.innerHeight -
        (this.$refs.intro?.clientHeight ?? 0) -
        (this.$refs.navigation?.clientHeight ?? 0) -
        headerHeight -
        sliderPaddingsTotal;

      const widestMediaDimensions = this.slideContent.reduce((prev, curr) => {
        return prev.SlideContent.width > curr.SlideContent.width
          ? prev
          : curr;
      })?.SlideContent;

      let sliderTempHeight = widestMediaDimensions.height;

      if (widestMediaDimensions?.width > twoThirdScreen) {
        const scaleFactor = widestMediaDimensions.width / twoThirdScreen;
        sliderTempHeight = Math.max(
          widestMediaDimensions.height / scaleFactor,
          window.innerHeight / 3
        );
      }

      this.sliderHeight = Math.floor(
        Math.min(sliderTempHeight, sliderMaxHeight)
      );
      this.sliderWidths = this.slideContent.map((s) => {
        return Math.round(
          s.SlideContent.width /
            (s.SlideContent.height / this.sliderHeight)
        );
      });

      [...this.$refs.container.querySelectorAll('img')].forEach((i) => {
        i.addEventListener('load', () => {
          this.loadedMedia += 1;
        });
      });

      [...this.$refs.container.querySelectorAll('video')].forEach((i) => {
        this.loadedMedia += 1;
      });

      this.slidesCalculated = true;
    },
    hasMedia(slide) {
      return slide?.SlideContent?.id;
    },
    isVideo(slide) {
      return slide?.SlideContent?.templateId === 'Movie';
    },
    isAutoplay(slide) {
      return slide?.AutoplayVideo;
    },
    getMediaSource(slide) {
      let url = this.$toLitiumMediaUrl(slide?.SlideContent?.id, {
        maxHeight: Math.ceil(this.sliderHeight / 50) * 50,
      });
      if (this.isVideo(slide)) {
        url = url + '#t=0.001';
      }
      return url;
    },
    goPrev() {
      if (this.canScrollLeft) {
        const increment =
          (this.$refs.container.children[this.currentSlide + 1].clientWidth +
            this.$refs.container.children[this.currentSlide].clientWidth) /
          2;
        this.$refs.container.scrollLeft -= increment;
      }
    },
    goNext() {
      if (this.canScrollRight) {
        const increment =
          (this.$refs.container.children[this.currentSlide + 1].clientWidth +
            this.$refs.container.children[this.currentSlide + 2].clientWidth) /
          2;
        this.$refs.container.scrollLeft += increment;
      }
    },
    firstSpacerWidth() {
      return {
        width: `${Math.floor(
          this.$refs.container.clientWidth / 2 -
            this.$refs.container.children[1].clientWidth / 2
        )}px`,
      };
    },
    lastSpacerWidth() {
      return {
        width: `${Math.floor(
          this.$refs.container.clientWidth / 2 -
            this.$refs.container.children[
              this.$refs.container.children.length - 2
            ].clientWidth /
              2
        )}px`,
      };
    },
    getSlideElement(slide) {
      return this.slideLink(slide) ? 'router-link' : 'div';
    },
    slideLink(slide) {
      return slide.CompositeLinkPage?.url
        || slide.CompositeLinkProduct?.url
        || slide.CompositeLinkCategory?.url
        || slide.CompositeLinkSuffix
        || undefined;
    },
    overlayPositions(slide) {
      const res = {};
      const positions = [];

      const posPhone = slide.OverlayPositionPhone?.value || 'center';
      const posDesktop = slide.OverlayPositionDesktop?.value || 'center';

      function addPosition(value) {
        if (value === 'hidden') {
          return;
        }
        if (value && positions.indexOf(value) < 0) {
          positions.push(value);
        }
      }

      addPosition(posPhone);
      addPosition(posDesktop);

      positions.forEach((pos) => {
        if (!res[pos]) {
          res[pos] = [];
        }
        const showOn = [];
        if (pos === posPhone) {
          showOn.push('phone');
        }
        if (pos === posDesktop) {
          showOn.push('desktop');
        }
        res[pos].push({
          showOn,
          slide,
        });
      });

      return res;
    },
    getOverlayClassFor(item) {
      const classes = [
        'content-slider__overlay-item',
        `content-slider__overlay-item--phone-text-${
          item.slide.OverlayTextAlignmentPhone?.value || 'center'
        }`,
        `content-slider__overlay-item--desktop-text-${
          item.slide.OverlayTextAlignmentDesktop?.value || 'center'
        }`,
      ];
      item.showOn.forEach((device) => {
        classes.push(`content-slider__overlay-item--${device}`);
      });
      return classes;
    },
  },
};
</script>

<template>
  <div
    v-if="hasSlideContent"
    class="content-slider"
  >
    <div class="content-slider__intro" v-if="intro" v-html="intro" ref="intro"></div>
    <div
      :class="[
        'content-slider__list',
        { 'content-slider__list--single': displaySingleContent },
      ]"
      ref="container"
      :style="listAndNavStyle"
    >
      <div
        v-if="slidesCalculated && !displaySingleContent"
        class="content-slider__spacer"
        :style="firstSpacerWidth()"
      ></div>
      <component
        v-for="slide in slideContent"
        :is="slide.element"
        :to="slide.link"
        class="content-slider__slide"
        :style="sliderStyle"
        :key="slide.id"
      >
        <img
          v-if="slide.hasMedia && !slide.isVideo"
          :class="[
            'content-slider__slide-image',
            { 'content-slider__slide-image--single': displaySingleContent },
          ]"
          :src="slide.mediaSource"
          :height="sliderHeight"
          :width="slide.slideWidth"
        />
        <video
          preload="metadata"
          v-if="slide.hasMedia && slide.isVideo"
          class="content-slider__slide-video"
          :autoplay="slide.isAutoplay"
          :loop="slide.isAutoplay"
          :muted="slide.isAutoplay"
          :controls="!slide.isAutoplay"
          playsinline
        >
          <source :src="slide.mediaSource" />
        </video>
        <div
          v-for="position in slide.overlayClasses"
          :key="position.name"
          :class="[`content-slider__overlay--${position.name}`, 'content-slider__overlay']"
        >
          <div class="content-slider__overlay-container">
            <div
              v-for="item in position.items"
              :key="item"
              v-html="slide.OverlayBody"
              :class="item"
            ></div>
          </div>
        </div>
      </component>
      <div
        v-if="slidesCalculated && !displaySingleContent"
        class="content-slider__spacer"
        :style="lastSpacerWidth()"
      ></div>
    </div>

    <div
      v-if="!displaySingleContent"
      :style="listAndNavStyle"
      class="content-slider__navigation"
      ref="navigation"
    >
      <div
        @click="goPrev"
        class="content-slider__navigation-prev"
        :style="{
          opacity: canScrollLeft ? 1 : 0,
          pointerEvents: canScrollLeft ? 'auto' : 'none',
        }"
      ></div>
      <div
        @click="goNext"
        class="content-slider__navigation-next"
        :style="{
          opacity: canScrollRight ? 1 : 0,
          pointerEvents: canScrollRight ? 'auto' : 'none',
        }"
      ></div>
    </div>
  </div>
</template>

<style>
.content-slider {
  max-height: calc(100vh - 45px);
}

.content-slider__intro {
  max-width: 565px;
  margin: auto;
  text-align: center;
  padding: 1px 15px;
}

.content-slider__list {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0.5rem 0 22px;
  white-space: nowrap;
  width: 100%;
  visibility: hidden;
}

.content-slider__list--single {
  justify-content: center;
}

.content-slider__slide {
  list-style-type: none;
  padding: var(--half-grid-margin) 5px;
  flex-shrink: 0;
  scroll-snap-align: center;
  overflow: hidden;
  position: relative;
}

.content-slider__slide-image,
.content-slider__slide-video {
  outline: none;
  height: 100%;
  object-fit: cover;
  max-width: 75vw;
}

.content-slider__slide-image--single {
  height: auto;
  display: block;
}

.content-slider__spacer {
  flex-shrink: 0;
}

.content-slider__list::-webkit-scrollbar {
  display: none;
}

.content-slider__navigation {
  display: flex;
  max-width: var(--layout-maxWidth);
  margin: auto;
  padding: 0 1rem 1rem;
  visibility: hidden;
}

.content-slider__navigation-prev {
  background-image: url(../../static/icons/left-arrow.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

.content-slider__navigation-next {
  background-image: url(../../static/icons/right-arrow.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-left: auto;
  cursor: pointer;
  transition: opacity 0.2s linear;
}

@media (--tabletAndDesktop) {
  .content-slider__list {
    scroll-padding: 0;
  }

  .content-slider__slide {
    padding: var(--half-grid-margin) 10px;
  }
}

.content-slider__overlay {
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: var(--layout-maxWidth);
  display: flex;
  flex-direction: column;
  padding: 10px;
  pointer-events: none;
}

.content-slider__overlay-item {
  padding: 10px;
  display: none;
  max-height: 100%;
  white-space: normal;
  pointer-events: auto;
}

.content-slider__overlay-item h1 {
  letter-spacing: -0.3px;
  font-size: 55px;
  margin: 0;
}

.content-slider__overlay-item h2 {
  font-size: 40px;
  margin: 0;
}

.content-slider__overlay-item h3 {
  font-size: 30px;
  margin: 0;
}

.content-slider__overlay-item p {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.9px;
  margin: 8px 0;
}

@media (max-width: 767px) {
  .content-slider__overlay {
    padding: 10px;
  }

  .content-slider__overlay-item {
    padding: 5px;
    width: 100%;
  }

  .content-slider__overlay-item--phone {
    display: block;
  }

  .content-slider__overlay-item--phone-text-left {
    text-align: left;
  }

  .content-slider__overlay-item--phone-text-center {
    text-align: center;
  }

  .content-slider__overlay-item--phone-text-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .content-slider__overlay-item--desktop {
    display: block;
  }

  .content-slider__overlay-item--desktop-text-left {
    text-align: left;
  }

  .content-slider__overlay-item--desktop-text-center {
    text-align: center;
  }

  .content-slider__overlay-item--desktop-text-right {
    text-align: right;
  }
}

/* Overlay positions */
.content-slider__overlay--top {
  align-items: center;
  justify-content: flex-start;
}

.content-slider__overlay--topleft {
  align-items: flex-start;
  justify-content: flex-start;
}

.content-slider__overlay--topright {
  align-items: flex-end;
  justify-content: flex-start;
}

.content-slider__overlay--left {
  align-items: flex-start;
  justify-content: center;
}

.content-slider__overlay--right {
  align-items: flex-end;
  justify-content: center;
}

.content-slider__overlay--center {
  align-items: center;
  justify-content: center;
}

.content-slider__overlay--bottom {
  align-items: center;
  justify-content: flex-end;
}

.content-slider__overlay--bottomleft {
  align-items: flex-start;
  justify-content: flex-end;
}

.content-slider__overlay--bottomright {
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
